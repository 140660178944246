import xhr from './xhr/';
/**
 * 医生相关的 API
 */
class DoctorService {
	// 医生促进服务管理首页 获取后台传过来的select配置项
	getDoctorSelect(data) {
		return xhr({
			method: 'get',
			url: '/doctor/index',
			params: data,
		});
	}

	// 搜索
	getDoctorList(data) {
		return xhr({
			method: 'get',
			url: '/doctor/list',
			params: data,
		});
	}

	// 获取某人所有的促进记录
	getRecordList(data) {
		return xhr({
			method: 'get',
			url: '/doctor/list_member',
			params: data,
		});
	}

	// 删除问题或记录
	deleteBoost(data) {
		return xhr({
			method: 'post',
			url: '/doctor/delete',
			params: data,
		});
	}

	// 分配医生
	distribute(data) {
		return xhr({
			method: 'post',
			url: '/doctor/distribution',
			params: data,
		});
	}

	// 分配医生
	distributeAll(data) {
		return xhr({
			method: 'post',
			url: '/doctor/all_distribution',
			params: data,
		});
	}

	// 获取某人某个促进记录详情
	getRecordDetail(data) {
		return xhr({
			method: 'get',
			url: '/doctor/detail',
			params: data,
		});
	}

	// 开始促进
	getStart(data) {
		return xhr({
			method: 'get',
			url: '/doctor/done_page',
			params: data,
		});
	}

	// 完成促进
	doctorDone(data) {
		return xhr({
			method: 'post',
			url: '/doctor/done',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new DoctorService();
